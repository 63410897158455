import { greenCheck, loginAsset, squidLogo } from "~/assets/assets";
import HubbleImage from "~/widgets/hubble_image";
import { PhosphorIcon } from "~/widgets/icons";
import { zIndexes } from "~/z_indexes";

export default function Complete() {
  return (
    <div class="flex h-screen">
      <div class=" max-h-screen w-[50%] bg-gray-400">
        <HubbleImage
          src={loginAsset}
          alt="login-asset"
          errorImage=""
          class="h-full w-full object-cover"
        />
        <div class="absolute left-14 top-10 flex flex-col items-start justify-start ">
          <div class="flex">
            <HubbleImage
              src={squidLogo}
              alt="logo-image"
              errorImage=""
              class="mr-1 h-10 w-10"
            />
            <div class="flex flex-col items-start justify-start">
              <div class="text-bold text-white">SQUID</div>
              <div class="text-f12Bold text-white">By Hubble</div>
            </div>
          </div>
          <div class="mt-11 w-[330px] text-h1 text-white">
            For all your gift card needs
          </div>
          <div class="mt-4 flex items-center justify-start">
            <PhosphorIcon
              name="check"
              fontSize={18}
              class="text-baseTertiaryDark"
            />
            <div class="ml-[6px] text-normal text-baseTertiaryDark">
              450+ brands
            </div>
          </div>
          <div class="mt-4 flex items-center justify-start">
            <PhosphorIcon
              name="check"
              fontSize={18}
              class="text-baseTertiaryDark"
            />
            <div class="ml-[6px] text-normal text-baseTertiaryDark">
              Gest deals & prices
            </div>
          </div>
        </div>
      </div>
      <div class="flex max-h-screen w-[50%] flex-col items-center justify-center overflow-y-auto  align-middle">
        <div class="flex h-screen  flex-col items-center justify-center">
          <div
            class="h-[56px] w-[56px]"
            style={{
              "z-index": zIndexes.orderSuccessHeaderTick,
            }}
          >
            <img
              class="anim-order-success-check h-[56px] w-[56px]"
              src={greenCheck}
              alt="brand card"
            />
          </div>
          <div class="my-3 text-bold text-successDark">Details submitted</div>
          <div class="w-[400px] text-center text-h3 text-textDark">
            Someone from our team will contact you in 3-5 days for next steps
          </div>
        </div>
      </div>
    </div>
  );
}
